import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import stravaConnectButton from "../assets/connect_with_strava.png";
import {
  FaSquareReddit,
  FaUserAstronaut,
  FaPersonRunning,
  FaWandMagicSparkles,
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareThreads,
  FaSquareXTwitter,
} from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import poweredByStrava from "../assets/powered_by_strava.png";
import { Link } from "react-router-dom";
import BlogList from "./BlogList";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f7f7f7;
  font-family: "Arial", sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #fc4c02;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const Subtitle2 = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  max-width: 600px;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.6;
  margin-top: 0.5rem;
`;

const EmailInput = styled.input`
  width: 300px;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.84rem;
`;

const ConnectButton = styled.img`
  cursor: ${(props) => (props.isValid ? "pointer" : "not-allowed")};
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};
  transition: opacity 0.3s ease;
  width: 212px;
  &:hover {
    opacity: ${(props) => (props.isValid ? 0.8 : 0.5)};
  }
`;

const ErrorMessage = styled.div`
  background-color: #ffe6e6;
  color: #d8000c;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 600px;
`;

const CommentSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 1rem 0;
`;

const CommentTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  text-align: center;
  margin-top: 0rem;
`;

const SliderWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding: 0 0 20px 0;
  .slick-track {
    display: flex !important;
    align-items: center !important; // Center align items
  }
  .slick-slide {
    height: auto;
    margin: 3px; // Reduced margin for less padding between tiles
    > div {
      height: 100%;
      display: flex;
      align-items: center; // Center content vertically
    }
  }
  .slick-dots li button:before {
    color: #fc4c02;
  }
  .slick-dots li.slick-active button:before {
    color: #fc4c02;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #fc4c02;
    font-size: 24px;
  }
  .slick-prev {
    left: -24px;
  }
  .slick-next {
    right: -24px;
  }
  .slick-list {
    margin: 0 -5px; // Adjusted margin to match padding
  }
  .slick-slide > div {
    padding: 0 5px; // Consistent padding
  }
`;

const CommentTile = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center; // Center vertically
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const PlatformIcon = styled.span`
  display: flex;
  align-items: center;
`;

const CommentAuthor = styled.span`
  font-weight: bold;
  color: #ff4500;
`;

const CommentContent = styled.p`
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
  margin-bottom: 0.4rem;
`;

const FeaturesSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
`;

const FeatureList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

const FeatureItem = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: calc(30% - 1rem);
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  color: #fc4c02;
  margin-top: 0rem;
`;

const FeatureName = styled.h4`
  font-size: 1.1rem;
  color: #333;
  margin: 0.5rem 0;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin: 0.5rem 0;
`;

const CTAButton = styled(Link)`
  background-color: #fc4c02;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
  font-weight: 600;

  &:hover {
    background-color: #e34400;
    transform: translateY(-1px) translateX(1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const features = [
  {
    name: "Strava Profile Roast",
    description: "Get a custom roast based on your Strava profile and stats.",
    icon: <FaUserAstronaut />,
    cta: "Get Roasted",
    link: "/dashboard",
  },
  {
    name: "Strava Activity Roast",
    description: "Witty commentary on your latest Strava activities.",
    icon: <FaPersonRunning />,
    cta: "Roast My Activities",
    link: "/activity",
  },
  {
    name: "Strava Title Generator",
    description:
      "Generate creative and funny titles for your Strava activities.",
    icon: <FaWandMagicSparkles />,
    cta: "Generate Titles",
    link: "/strava-title-generator",
  },
];

const BlogSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
`;

const BlogTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
`;

function About() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const existingToken = localStorage.getItem("strava_token");
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const error = params.get("error");
    const email = params.get("email");

    if (error) {
      if (error === "access_denied") {
        setErrorMessage(
          "You've denied access to Strava. To use Roast My Strava, please allow access to your Strava data."
        );
      } else if (error === "strava_api" || error === "rate_limit") {
        setErrorMessage(
          "Oops! We're experiencing high traffic. We've saved your email and will notify you when we're back up and running. Thanks for your patience!"
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      } else if (error === "internal_server") {
        setErrorMessage(
          "Oops! We hit a wall. Our servers are catching their breath. We've saved your email and will notify you when we're back up. Please try again later."
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      }
    } else if (token) {
      localStorage.setItem("strava_token", token);
      setIsAuthenticated(true);
    } else if (existingToken) {
      setIsAuthenticated(true);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleLogin = () => {
    if (isEmailValid) {
      window.location.href = `https://roast-my-strava-backend-production.up.railway.app/login?email=${encodeURIComponent(
        email
      )}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isEmailValid) {
      handleLogin();
    }
  };

  const comments = [
    // {
    //   author: "u/SzechuanSaucelord",
    //   content:
    //     "This was fun, now if AI could interpret all my Strava data and develop coaching feedback that would be amazing.",
    //   url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnhvlo7/",
    //   platform: "Reddit",
    // },

    {
      author: "@running_bucky",
      content: "Get your Strava roasted… made me chuckle!",
      url: "https://www.instagram.com/p/DAVTqwRtx3c/",
      platform: "Instagram",
    },
    {
      author: "Derek M.",
      content: "RoastMyStrava.com is the laugh I needed tonight 😂",
      url: null,
      platform: "Facebook",
    },
    {
      author: "u/RealHek",
      content: "I'm in bits! This was the highlight of my day! Well done.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lni9msk/",
      platform: "Reddit",
    },
    {
      author: "@trail_tortoise_running",
      content:
        "Has anyone seen the RoastMyStrava.com website yet!? This is pure gold!",
      url: "https://www.threads.net/@trail_tortoise_running/post/DAojW_NPf7Y",
      platform: "Threads",
    },
    {
      author: "Jill H.",
      content:
        "The 'Roast My Strava' AI is supposed to be mean, but this one is not all that mean. 😆",
      url: null,
      platform: "Facebook",
    },
    {
      author: "@MelindaHoward4",
      content: "Have y’all seen this #StravaRoast? Hilarious!",
      url: "https://twitter.com/MelindaHoward4/status/1841581218518667458",
      platform: "XTwitter",
    },
    {
      author: "u/FlyMyPretty",
      content:
        "I'm quite impressed that it used my stats AND my photo (which is me and a chicken).",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnho8sz/",
      platform: "Reddit",
    },
    {
      author: "@stantherunningman",
      content:
        "I couldn’t resist. I had to “Roast my Strava”… enjoy the laugh.",
      url: "https://www.instagram.com/p/DAgg3AlvISr",
      platform: "Instagram",
    },
    {
      author: "Paul N.",
      content: "Roast My Strava is funny! Go on, do it and have a laugh!",
      url: null,
      platform: "Facebook",
    },
    {
      author: "u/Zealousideal_Rich239",
      content:
        "Funny roasts with a good balance of compliments and punches so it doesn't actually hurt or offend. LOL.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lp5g1lk/",
      platform: "Reddit",
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    initialSlide: Math.floor(Math.random() * comments.length), // Start at a random slide
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "Reddit":
        return <FaSquareReddit color="#ff4500" size={18} />;
      case "Facebook":
        return <FaSquareFacebook color="#1877f2" size={18} />;
      case "Threads":
        return <FaSquareThreads color="#000000" size={18} />;
      case "Instagram":
        return <FaSquareInstagram color="#E1306C" size={18} />;
      case "XTwitter":
        return <FaSquareXTwitter color="#000000" size={18} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Roast My Strava | About</title>
        <meta
          name="description"
          content="Roast My Strava is an AI tool that generates personalized commentary on your Strava profile and activities, in the style of a roast. Connect your Strava account for a humorous take on your fitness journey!"
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, roastmystrava, Strava AI,running, cycling, humor, fitness"
        />
      </Helmet>
      <Title>
        <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
          Roast My Strava
        </Link>
      </Title>
      <Subtitle style={{ marginTop: 0 }}>What is Roast My Strava?</Subtitle>
      <Description style={{ marginTop: 0, marginBottom: "2rem" }}>
        <Link to="/">Roast My Strava</Link> is a fun tool that generates witty,
        personalized commentary on your Strava profile and activities. Simply
        connect your Strava account and then generate a roast to see what our AI
        says about you!
      </Description>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <EmailInput
        type="email"
        name="email"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyPress}
        placeholder="Enter your email"
        autoComplete="email"
      />
      <ConnectButton
        src={stravaConnectButton}
        alt="Connect with Strava"
        onClick={handleLogin}
        isValid={isEmailValid}
      />
      <Subtitle2 style={{ marginTop: "2rem" }}>
        How does Roast My Strava work?
      </Subtitle2>
      <Description>
        Our app has been approved by the{" "}
        <a
          href="https://developers.strava.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Strava team
        </a>
        , so we are able to securely connect to your Strava account using the
        Strava API. Once you connect your account, we use your Strava profile
        and activities, along with an AI model (similar to ChatGPT), to generate
        a unique roast just for you.
        <br />
        <br />
        If you want to see what it's like, before connecting your Strava
        account, head over to the <Link to="/demo">Demo Roast</Link> page to get
        a preview of our Strava Profile Roast.
      </Description>
      <img
        src={poweredByStrava}
        alt="Powered by Strava"
        style={{
          width: "120px",
          display: "block",
          margin: "12px auto",
        }}
      />
      <FeaturesSection>
        <FeatureTitle>Unleash the Power of AI on Your Strava</FeatureTitle>
        <FeatureList>
          {features.map((feature, index) => (
            <FeatureItem key={index}>
              <FeatureIcon>{feature.icon}</FeatureIcon>
              <FeatureName>{feature.name}</FeatureName>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <CTAButton to={feature.link}>{feature.cta}</CTAButton>
            </FeatureItem>
          ))}
        </FeatureList>
      </FeaturesSection>
      <Subtitle2>
        Why did you make Roast My Strava and what's the story?
      </Subtitle2>
      <Description>
        As lifelong runners and triathletes, we love Strava and the community
        that it has helped to grow for runners, cyclists, and athletes around
        the world. We also love new technology like generative AI.
        <br />
        <br />
        Inspired by the{" "}
        <a
          href="https://www.nbcnews.com/tech/social-media/viral-meme-asks-chatgpt-roast-instagram-feed-rcna167408"
          target="_blank"
          rel="noopener noreferrer"
        >
          "asking ChatGPT to roast your Instagram feed"
        </a>{" "}
        trend, we wanted to bring that same fun to Strava.
        <br />
        <br />
        If you want to connect with the team to chat more about the project,
        please email us at{" "}
        <a href="mailto:roastmystrava@gmail.com">roastmystrava@gmail.com</a>
      </Description>

      <CommentSection>
        <CommentTitle>🚴‍♂️ Community Reactions 🏃‍♀️</CommentTitle>
        <SliderWrapper>
          <Slider {...sliderSettings}>
            {comments.map((comment, index) => (
              <CommentTile
                key={index}
                href={comment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CommentHeader>
                  <PlatformIcon>
                    {getPlatformIcon(comment.platform)}
                  </PlatformIcon>
                  <CommentAuthor>{comment.author}</CommentAuthor>
                </CommentHeader>
                <CommentContent>{comment.content}</CommentContent>
              </CommentTile>
            ))}
          </Slider>
        </SliderWrapper>
      </CommentSection>
      <BlogSection>
        <BlogTitle>Latest from Our Blog</BlogTitle>
        <BlogList />
      </BlogSection>
    </Container>
  );
}

export default About;
