import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BlogListContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
`;

const BlogItem = styled(Link)`
  display: block;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  text-decoration: none;
  color: #333;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
  }
`;

const BlogHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

const BlogTitle = styled.h3`
  color: #fc4c02;
  font-size: 1.4rem;
  margin: 0;
`;

const BlogDate = styled.span`
  color: #666;
  font-size: 0.9rem;
`;

const BlogPreview = styled.p`
  color: #444;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
`;

const BlogList = () => {
  const blogPosts = [
    // {
    //   id: 1,
    //   title: "Getting Started with Roast My Strava",
    //   slug: "getting-started",
    //   date: "September 20, 2024",
    //   intro:
    //     "Learn how to connect your Strava account and get your first roast with our step-by-step guide.",
    // },
    {
      id: 2,
      title: "Strava Announces Athlete Intelligence",
      slug: "strava-announces-athlete-intelligence",
      date: "October 4, 2024",
      intro:
        "Strava introduces Athlete Intelligence, a groundbreaking AI-powered feature that revolutionizes how athletes interact with their fitness data, providing personalized performance insights.",
    },
  ];

  return (
    <BlogListContainer>
      {blogPosts.map((post) => (
        <BlogItem key={post.id} to={`/blog/${post.slug}`}>
          <BlogHeader>
            <BlogTitle>{post.title}</BlogTitle>
            <BlogDate>{post.date}</BlogDate>
          </BlogHeader>
          <BlogPreview>{post.intro}</BlogPreview>
        </BlogItem>
      ))}
    </BlogListContainer>
  );
};

export default BlogList;
