import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import poweredByStrava from "../assets/powered_by_strava.png";

function Dashboard() {
  const [roastResult, setRoastResult] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const [roastIntensity, setRoastIntensity] = useState(5);

  const handleIntensityChange = (event) => {
    setRoastIntensity(parseInt(event.target.value, 10));
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const token = localStorage.getItem("strava_token");
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/user_profile",
        {
          params: { token },
          withCredentials: true,
        }
      );
      setUserProfile(response.data);
      return response.data;
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.status === 429 ||
          error.response.status === 403)
      ) {
        localStorage.removeItem("strava_token");
        navigate("/");
      } else {
        console.error("Error fetching user profile:", error);
      }
    }
  }, [navigate]);

  const checkLoginStatus = useCallback(async () => {
    console.log("Checking login status");
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        console.log("No token found, redirecting to home");
        navigate("/");
        return;
      }
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/check_auth",
        {
          params: { token },
          withCredentials: true,
        }
      );
      if (!response.data.authenticated) {
        console.log("Not authenticated, clearing token and redirecting");
        localStorage.removeItem("strava_token");
        navigate("/");
      } else {
        await fetchUserProfile();
      }
    } catch (error) {
      localStorage.removeItem("strava_token");
      navigate("/");
    }
  }, [navigate, fetchUserProfile]);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  const handleRoast = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("strava_token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await axios.get(
        "https://roast-my-strava-backend-production.up.railway.app/roast",
        {
          params: { token, intensity: roastIntensity },
          withCredentials: true,
        }
      );
      setRoastResult(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.status === 429) {
        // Rate limit error
        navigate("/", { state: { error: "rate_limit" } });
      } else if (
        error.response &&
        error.response.data.error === "Error communicating with Strava API"
      ) {
        navigate("/", { state: { error: "strava_api" } });
      } else {
        setRoastResult(
          "Oops! Looks like we hit a wall. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(
        `I just got my Strava roasted by an AI...\n\n${roastResult}\n\nGet your Strava roasted at https://roastmystrava.com`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("strava_token");
    navigate("/");
  };

  return (
    <div className="Dashboard">
      <h1 className="roast-header">Roast My Strava</h1>
      <div className="user-info">
        <div className="profile-info">
          {userProfile ? (
            <>
              <img
                src={userProfile.profile}
                alt="Profile"
                className="profile-image"
              />
              <div className="user-details">
                <h1>
                  {userProfile.firstname} {userProfile.lastname}
                </h1>
                <span className="user-location">
                  {userProfile.city}, {userProfile.state}, {userProfile.country}
                </span>
              </div>
            </>
          ) : (
            <div className="loading-message">
              <p>Connecting to Strava...</p>
            </div>
          )}
        </div>
      </div>
      {userProfile && (
        <div className="roast-container">
          <div className="intensity-slider-container">
            <div className="intensity-slider">
              <span role="img" aria-label="Tame">
                🧊
              </span>
              <input
                id="intensity-slider"
                type="range"
                min="0"
                max="10"
                value={roastIntensity}
                onChange={handleIntensityChange}
                className="slider"
              />
              <span role="img" aria-label="Intense">
                🌶️
              </span>
            </div>
          </div>
          <button
            onClick={() => handleRoast(localStorage.getItem("strava_token"))}
            disabled={loading}
            className="roast-button"
          >
            {loading ? "Generating Roast..." : "Roast My Strava 🔥"}
          </button>
          {roastResult && (
            <>
              <p className="roast-result">{roastResult}</p>
              <div className="share-buttons">
                <button onClick={handleShare}>
                  {copied ? "Copied! 📋" : "Copy Roast 📝"}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {userProfile && (
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      )}
      <img
        src={poweredByStrava}
        alt="Powered by Strava"
        style={{
          width: "120px",
          display: "block",
          margin: "12px auto",
        }}
      />
    </div>
  );
}

export default Dashboard;
