import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import ActivityRoast from "./components/ActivityRoast";
import About from "./components/About";
import Demo from "./components/Demo";
import AnalyzeActivity from "./components/AnalyzeActivity";
import TitleGenerator from "./components/TitleGenerator";
import BlogPost from "./components/BlogPost";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/activity-roast" element={<ActivityRoast />} />
        <Route path="/about" element={<About />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/activity" element={<AnalyzeActivity />} />
        <Route path="/strava-title-generator" element={<TitleGenerator />} />
        <Route path="/blog" element={<Navigate to="/" replace />} />
        <Route path="/blog/:blogName" element={<BlogPost />} />
      </Routes>
    </Router>
  );
}

export default App;
