import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import poweredByStrava from "../assets/powered_by_strava.png";
import { Helmet } from "react-helmet";
import html2canvas from "html2canvas";

function Demo() {
  const [roastResult, setRoastResult] = useState("");
  const [userProfile, setUserProfile] = useState("dummyUserProfile");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const [roastIntensity, setRoastIntensity] = useState(5);
  const [unitPreference, setUnitPreference] = useState("kilometers");

  const [loadingEmoji, setLoadingEmoji] = useState("🏃‍♂️");
  const athleticEmojis = [
    "🏃‍♂️",
    "🚴‍♀️",
    "🏊‍♂️",
    "🏃🏻‍♀️",
    "🏋️‍♀️",
    "🤸‍♀️",
    "🏄‍♂️",
    "🏃🏿‍♂️",
    "🚣‍♀️",
    "🧘‍♀️",
    "🤾‍♂️",
    "🚴🏾‍♀️",
    "🤼‍♀️",
    "🏇",
    "🤺",
    "🏌️‍♂️",
    "⛹️‍♂️",
    "🏂",
    "🧗‍♀️",
    "🤽‍♂️",
    "🚵‍♀️",
  ];

  const loadingIntervalRef = useRef(null);

  const dummyUserProfile = useMemo(
    () => ({
      firstname: "Collin",
      lastname: "Williams",
      profile:
        "https://www.livingstondaily.com/gcdn/presto/2022/10/21/PLID/a90e1cdc-8f04-4e0b-be5c-eaba1528c818-IMG_2350_-_Sandula.JPG",
      city: "San Francisco",
      state: "California",
      country: "United States",
    }),
    []
  );

  // Dummy roast results
  const dummyRoasts = {
    mild: {
      kilometers: `Hey Collin, I see you knocked out 5km this week. I'm guessing that was a commute to your coffee shop? Might want to tack on a few extra kilometers next time! ☕️🏃‍♂️\n\nBut seriously, 5km is a start. It's like the "Hello World" of running – basic, but necessary. Maybe next week you can upgrade to the equivalent of a for-loop and actually repeat those steps a few more times?\n\nRemember, running is like debugging code: it gets easier with practice, and you'll eventually stop wanting to throw your shoes out the window. Keep at it, and soon you'll be sprinting through kilometers like you sprint through Stack Overflow answers!`,

      miles: `Hey Collin, I see you hit 3 miles this week. Are you warming up for a tech conference or actually trying to run? Either way, it's a start! 🏃‍♂️💼\n\nThree miles, huh? That's about as impressive as a "junior developer" title on LinkedIn. But don't worry, we all start somewhere. Maybe next week you can aim for the "mid-level developer" equivalent of mileage?\n\nRemember, running is like coding: the more you do it, the less it hurts (your brain or your legs). Keep pushing those commits to your health repository, and soon you'll be running circles around your sedentary colleagues.\n\nWho knows? With enough practice, you might even outpace your code compilation times. Keep it up, Collin!`,
    },
    medium: {
      kilometers: `Wow, Collin! 15km this week? You sure that wasn't just a scooter ride you accidentally tracked on Strava? Time to step it up! 🛴📲\n\nBut let's give credit where it's due. 15km is like successfully deploying a "Hello World" app to production – it's more than some people ever achieve. You're no longer in the "I only run when chased" category. Congratulations on leveling up from "couch potato" to "active potato"!\n\nNow, imagine if you put as much effort into running as you do into optimizing your code. You'd be leaving Usain Bolt in the dust! Remember, every step counts, just like every line of code. Keep pushing those boundaries, and soon you'll be running marathons faster than your CI/CD pipeline.\n\nWho knows? Maybe one day you'll even outrun your impostor syndrome. Keep crushing it, Collin!`,

      miles: `Wow, Collin! 9 miles? That's almost as many lines of code as you push in a day! Maybe try running without multitasking next time. 👨‍💻🏃‍♂️\n\nBut seriously, 9 miles is not too shabby. It's like successfully refactoring a legacy codebase – painful, time-consuming, but ultimately rewarding. You're no longer in the "I only run to catch the bus" league. Welcome to the "I occasionally jog for fun" club!\n\nImagine if you applied the same dedication to running as you do to debugging. You'd be leaving Mo Farah in your dust! Remember, every mile is like a successful commit – it brings you closer to your goal. Keep pushing those limits, and soon you'll be running ultramarathons faster than your unit tests.\n\nWho knows? Maybe one day you'll even outpace your coffee consumption. Keep on trucking, Collin!`,
    },
    spicy: {
      kilometers: `Holy smokes, Collin! 30km? Did you finally decide to run to all your meetings? Either that, or Strava's overestimating your mileage—impressive, though! 👟💻\n\nBut let's be real for a second. 30km is no joke. It's like successfully deploying a major update without any bugs – rare, painful, but incredibly satisfying. You've officially graduated from "occasional jogger" to "that annoying friend who won't shut up about running".\n\nImagine if you applied this level of commitment to your coding. You'd be the next Linus Torvalds! Remember, every kilometer you run is like a line of clean, efficient code – it might hurt to produce, but it's beautiful in action.\n\nKeep this up, and soon you'll be running marathons in less time than it takes to compile your projects. Who knows? Maybe one day you'll even outrun your impostor syndrome. Keep crushing it, Collin!`,

      miles: `Geez, Collin! 18 miles? Did you finally disconnect from Slack or just get really, really lost? Either way, impressive distance for a tech bro! 🏞️📉\n\nBut let's give credit where it's due. 18 miles is seriously impressive. It's like fixing a critical bug in production without causing ten more – painful, terrifying, but ultimately heroic. You've officially leveled up from "casual runner" to "that guy who makes everyone feel bad about their fitness".\n\nImagine if you channeled this energy into your coding. You'd be the next Ada Lovelace! Remember, every mile you run is like a perfectly executed algorithm – it might be tough to implement, but the results are undeniable.\n\nKeep this pace up, and soon you'll be running ultramarathons faster than your code can execute. Who knows? Maybe one day you'll even outpace the endless stream of JavaScript frameworks. Keep dominating, Collin!`,
    },
  };

  const handleIntensityChange = (event) => {
    setRoastIntensity(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    // Set dummy user profile
    setUserProfile(dummyUserProfile);
    setUnitPreference(
      dummyUserProfile.country === "United States" ? "miles" : "kilometers"
    );
  }, [dummyUserProfile]);

  const handleRoast = () => {
    setLoading(true);
    startLoadingAnimation();

    // Simulate API call with setTimeout
    setTimeout(() => {
      let roastCategory;
      if (roastIntensity <= 3) roastCategory = "mild";
      else if (roastIntensity <= 7) roastCategory = "medium";
      else roastCategory = "spicy";

      setRoastResult(dummyRoasts[roastCategory][unitPreference]);
      setLoading(false);
      stopLoadingAnimation();
    }, 1000);
  };

  const startLoadingAnimation = () => {
    let index = 0;
    loadingIntervalRef.current = setInterval(() => {
      setLoadingEmoji(athleticEmojis[index]);
      index = (index + 1) % athleticEmojis.length;
    }, 200);
  };

  const stopLoadingAnimation = () => {
    if (loadingIntervalRef.current) {
      clearInterval(loadingIntervalRef.current);
      loadingIntervalRef.current = null;
      setLoadingEmoji("🏃‍♂️"); // Reset to initial emoji if desired
    }
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(
        `I just got my Strava roasted by an AI...\n\n${roastResult}\n\nGet your Strava roasted at https://roastmystrava.com`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleSaveRoast = () => {
    const roastContainer = document.querySelector(".roast-result-container");
    if (roastContainer) {
      const scale = 10; // Increase scale for better quality
      const originalWidth = roastContainer.offsetWidth;
      const originalHeight = roastContainer.offsetHeight;

      html2canvas(roastContainer, {
        useCORS: true,
        allowTaint: false,
        scale: scale, // Use the higher scale factor
        logging: true, // Enable logging for debugging
        imageTimeout: 0, // Remove timeout for image loading
        width: originalWidth,
        height: originalHeight,
        onclone: (clonedDoc) => {
          // Adjust image sizes in the cloned document
          clonedDoc.querySelectorAll("img").forEach((img) => {
            img.style.width = `${img.offsetWidth}px`;
            img.style.height = `${img.offsetHeight}px`;
          });
        },
      }).then((canvas) => {
        // Create a new canvas with padding
        const padding = 40 * scale; // Scale padding as well
        const newCanvas = document.createElement("canvas");
        const context = newCanvas.getContext("2d");
        newCanvas.width = canvas.width + padding * 2;
        newCanvas.height = canvas.height + padding * 2;

        // Fill the new canvas with white background
        context.fillStyle = "#FFFFFF";
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Draw the original canvas onto the new canvas with padding
        context.drawImage(canvas, padding, padding);

        // Create download link with high-quality PNG
        const link = document.createElement("a");
        link.download = `roastmystrava_${
          userProfile.firstname?.toLowerCase() || ""
        }${userProfile.firstname && userProfile.lastname ? "_" : ""}${
          userProfile.lastname?.toLowerCase() || ""
        }_${formatDate(new Date())}.png`;
        link.href = newCanvas.toDataURL("image/png", 1.0); // Use maximum quality
        link.click();
      });
    }
  };

  const formatDate = (date) => {
    return date.toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const handleLogout = () => {
    localStorage.removeItem("strava_token");
    navigate("/");
  };

  // Cleanup interval on component unmount
  useEffect(() => {
    return () => {
      if (loadingIntervalRef.current) {
        clearInterval(loadingIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Roast My Strava | Demo</title>
        <meta
          name="description"
          content="View a demo of Roast My Strava. Roast My Strava is a website that uses AI to generate commentary based on your Strava profile and activities."
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, roastmystrava, Strava AI,running, cycling, humor, fitness"
        />
      </Helmet>
      <h1 className="roast-header">Roast My Strava Demo Page</h1>
      <div className="user-info">
        <div className="profile-info">
          {userProfile ? (
            <>
              <img
                src={userProfile.profile}
                alt="Profile"
                className="profile-image"
              />
              <div className="user-details">
                <h1>
                  {userProfile.firstname} {userProfile.lastname}
                </h1>
                <span className="user-location">
                  {[userProfile.city, userProfile.state, userProfile.country]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            </>
          ) : (
            <div className="loading-message">
              <p>Connecting to Strava...</p>
            </div>
          )}
        </div>
      </div>
      {userProfile && (
        <div className="roast-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
              backgroundColor: "#f0f0f0",
              borderRadius: "20px",
              padding: "3px",
              width: "280px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <button
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "miles" ? "#FC4C02" : "transparent",
                color: unitPreference === "miles" ? "white" : "#333",
                cursor: "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
              onClick={() => setUnitPreference("miles")}
            >
              <span
                role="img"
                aria-label="US Flag"
                style={{ fontSize: "1.2em" }}
              >
                🇺🇸
              </span>
              Miles
            </button>
            <button
              style={{
                flex: 1,
                padding: "8px 12px",
                border: "none",
                backgroundColor:
                  unitPreference === "kilometers" ? "#FC4C02" : "transparent",
                color: unitPreference === "kilometers" ? "white" : "#333",
                cursor: "pointer",
                borderRadius: "17px",
                transition: "background-color 0.3s, color 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                fontSize: "0.9em",
              }}
              onClick={() => setUnitPreference("kilometers")}
            >
              <span role="img" aria-label="World" style={{ fontSize: "1.2em" }}>
                🌍
              </span>
              Kilometers
            </button>
          </div>
          <div className="intensity-slider-container">
            <div className="intensity-slider">
              <span role="img" aria-label="Tame">
                🧊
              </span>
              <input
                id="intensity-slider"
                type="range"
                min="0"
                max="10"
                value={roastIntensity}
                onChange={handleIntensityChange}
                className="slider"
              />
              <span role="img" aria-label="Intense">
                🌶️
              </span>
            </div>
          </div>
          <button
            onClick={handleRoast}
            disabled={loading}
            className="roast-button"
          >
            {loading
              ? `Generating Roast... ${loadingEmoji}`
              : "Roast My Strava 🔥"}
          </button>
          {roastResult && (
            <>
              <div className="roast-result-container">
                <div className="roast-result-text">{roastResult}</div>
                <div className="roast-result-footer">
                  <div className="user-profile-mini">
                    <img
                      src={userProfile.profile}
                      alt={`${userProfile.firstname}'s profile`}
                      className="mini-profile-image"
                      crossOrigin="anonymous" // Added crossOrigin attribute
                    />
                    <div className="user-info-mini">
                      <span className="user-name-mini">
                        {[userProfile.firstname, userProfile.lastname]
                          .filter(Boolean)
                          .join(" ")}
                      </span>
                      <span className="user-location-mini">
                        {[userProfile.city, userProfile.state]
                          .filter(Boolean)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                  <div className="roast-source-container">
                    <div className="roast-source">RoastMyStrava.com</div>
                    <img
                      src={poweredByStrava}
                      alt="Powered by Strava"
                      className="powered-by-strava"
                    />
                  </div>
                </div>
              </div>
              <div className="share-buttons">
                <button onClick={handleShare}>
                  {copied ? "Copied! 📋" : "Copy Roast 📝"}
                </button>
                <button onClick={handleSaveRoast}>Save Roast 💾</button>
              </div>
            </>
          )}
        </div>
      )}
      {userProfile && (
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      )}
      <img
        src={poweredByStrava}
        alt="Powered by Strava"
        style={{
          width: "120px",
          display: "block",
          margin: "12px auto",
        }}
      />
    </div>
  );
}

export default Demo;
