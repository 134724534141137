import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import stravaConnectButton from "../assets/connect_with_strava.png";
import DOMPurify from "dompurify";
import { FaReddit } from "react-icons/fa";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f7f7f7;
  font-family: "Arial", sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #fc4c02;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  max-width: 600px;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.6;
  margin-top: 1.5rem;
`;

const EmailInput = styled.input`
  width: 300px;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.84rem;
`;

const ConnectButton = styled.img`
  cursor: ${(props) => (props.isValid ? "pointer" : "not-allowed")};
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};
  transition: opacity 0.3s ease;
  width: 212px;
  &:hover {
    opacity: ${(props) => (props.isValid ? 0.8 : 0.5)};
  }
`;

const FAQSection = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
`;

const FAQQuestion = styled.button`
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #fc4c02;
  }

  &:after {
    content: ${(props) => (props.expanded ? '"−"' : '"+"')};
    font-size: 1.2rem;
  }
`;

const FAQAnswer = styled.div`
  font-size: 0.9rem;
  color: #888;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  line-height: 1.4;

  a {
    color: #fc4c02;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMessage = styled.div`
  background-color: #ffe6e6;
  color: #d8000c;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  max-width: 600px;
`;

const Alert = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 600px;
  border: 1px solid #ffeeba;
`;

const CommentSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 1rem 0;
`;

const CommentTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  text-align: center;
  margin-top: 0rem;
`;

const CommentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`;

const CommentTile = styled.a`
  display: block;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CommentAuthor = styled.span`
  font-weight: bold;
  color: #ff4500;
`;

const CommentContent = styled.p`
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
`;

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [expandedFAQ, setExpandedFAQ] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const existingToken = localStorage.getItem("strava_token");
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const error = params.get("error");
    const email = params.get("email");

    if (error) {
      if (error === "access_denied") {
        setErrorMessage(
          "You've denied access to Strava. To use Roast My Strava, please allow access to your Strava data."
        );
      } else if (error === "strava_api" || error === "rate_limit") {
        setErrorMessage(
          "Oops! We're experiencing high traffic and have hit Strava's rate limit. We've saved your email and will notify you when we're back up and running. Thanks for your patience!"
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      } else if (error === "internal_server") {
        setErrorMessage(
          "Oops! We hit a wall. Our servers are catching their breath. We've saved your email and will notify you when we're back up. Please try again later."
        );
        if (email) {
          setEmail(email);
          setIsEmailValid(true);
        }
      }
    } else if (token) {
      localStorage.setItem("strava_token", token);
      setIsAuthenticated(true);
    } else if (existingToken) {
      setIsAuthenticated(true);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleLogin = () => {
    if (isEmailValid) {
      window.location.href = `https://roast-my-strava-backend-production.up.railway.app/login?email=${encodeURIComponent(
        email
      )}`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isEmailValid) {
      handleLogin();
    }
  };

  const faqData = [
    {
      question: "What information do you store?",
      answer:
        "We keep it simple: just your email (for updates on new features) and basic Strava info like your name and profile picture. Rest assured, we never share your data with anyone else.",
    },
    {
      question: "How does this work?",
      answer:
        "It's pretty straightforward: you connect your Strava account, we fetch some basic stats and recent activities via the Strava API, then our AI (similar to ChatGPT) generates a unique, light-hearted roast based on your data.",
    },
    {
      question: "What's next for Roast My Strava?",
      answer:
        "We're exploring new ideas to make your fitness journey more fun and engaging. An AI running coach is one possibility. Got any cool suggestions? We'd love to hear them, just send a note to <a href='mailto:roastmystrava@gmail.com'>roastmystrava@gmail.com</a>.",
    },
    {
      question: "How can I contact you?",
      answer:
        "Have questions, suggestions, want to work together, or just want to say hi? Drop us a line at <a href='mailto:roastmystrava@gmail.com'>roastmystrava@gmail.com</a> — we'd love to hear from you!",
    },
  ];

  const toggleFAQ = (index) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  const comments = [
    {
      author: "u/RealHek",
      content: "I'm in bits! This was the highlight of my day! Well done.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lni9msk/",
    },
    {
      author: "u/SzechuanSaucelord",
      content:
        "This was fun, now if AI could interpret all my Strava data and develop coaching feedback that would be amazing.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnhvlo7/",
    },
    {
      author: "u/taclovitch",
      content: "A great tool, silly fun. Props to you for making it.",
      url: "https://www.reddit.com/r/Strava/comments/1fiiv2b/comment/lnhqdxl/",
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>
          Roast My Strava - Humorous Takes on Your Workouts Powered by AI
        </title>
        <meta
          name="description"
          content="Connect your Strava account and get witty, AI-generated commentary on your running and cycling activities. A fun way to celebrate your fitness journey!"
        />
        <meta
          name="keywords"
          content="Strava, AI, roast my strava, Strava AI,running, cycling, humor, fitness"
        />
      </Helmet>
      <Title>Roast My Strava</Title>
      <Subtitle>
        Get Hilariously Roasted for Your Athletic Achievements
      </Subtitle>
      <Alert>
        🏃‍♂️💨 Whoa, we just smashed our traffic PR! Thanks to a{" "}
        <a
          href="https://www.reddit.com/r/Strava/comments/1fiiv2b/my_friend_got_access_to_the_strava_api_and_built/"
          target="_blank"
          rel="noopener noreferrer"
        >
          popular post on r/Strava
        </a>
        , we're experiencing high volume.
        <br />
        <br />
        You might hit a wall trying to connect due to Strava API limitations.
        We're pushing through this endurance test and appreciate your patience!
        <br />
        <br />
        Strava team, if you're seeing this, we'd love a limit increase 👀
      </Alert>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <EmailInput
        type="email"
        name="email"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyPress}
        placeholder="Enter your email"
        autoComplete="email"
      />
      <ConnectButton
        src={stravaConnectButton}
        alt="Connect with Strava"
        onClick={handleLogin}
        isValid={isEmailValid}
      />
      <Description>
        Connect your Strava account and let our AI-powered roast generator
        create humorous, personalized commentary on your running and cycling
        activities. It's a fun way to celebrate your fitness journey and share
        laughs with friends!
      </Description>

      <CommentSection>
        <CommentTitle>🚴‍♂️ Community Reactions 🏃‍♀️</CommentTitle>
        <CommentGrid>
          {comments.map((comment, index) => (
            <CommentTile
              key={index}
              href={comment.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CommentHeader>
                <FaReddit color="#ff4500" size={14} />
                <CommentAuthor>{comment.author}</CommentAuthor>
              </CommentHeader>
              <CommentContent>{comment.content}</CommentContent>
            </CommentTile>
          ))}
        </CommentGrid>
      </CommentSection>

      <FAQSection>
        {faqData.map((faq, index) => (
          <FAQItem key={index}>
            <FAQQuestion
              onClick={() => toggleFAQ(index)}
              expanded={expandedFAQ === index}
            >
              {faq.question}
            </FAQQuestion>
            {expandedFAQ === index && (
              <FAQAnswer
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(faq.answer),
                }}
              />
            )}
          </FAQItem>
        ))}
      </FAQSection>
    </Container>
  );
}

export default Home;
