import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

const BlogPostContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const BlogTitle = styled.h1`
  color: #fc4c02;
`;

const BlogContent = styled.div`
  line-height: 1.6;

  img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
  }

  h2,
  h3,
  h4 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: #fc4c02;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const BackLink = styled(Link)`
  display: inline-block;
  margin: 2rem 0;
  color: #fc4c02;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const BlogPost = () => {
  const { blogName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blogPosts = {
    // "getting-started": {
    //   title: "Getting Started with Roast My Strava",
    //   intro:
    //     "Learn how to connect your Strava account and get your first roast with our step-by-step guide.",
    //   content: `
    //     <p>Welcome to Roast My Strava! This guide will walk you through the process of connecting your Strava account and getting your first roast.</p>
    //     <h2>Step 1: Sign Up</h2>
    //     <p>First, visit our <a href="/" target="_blank" rel="noopener noreferrer">homepage</a> and click on the "Connect with Strava" button.</p>
    //     <img src="/images/connect-strava-button.jpg" alt="Connect with Strava button" />
    //     <h2>Step 2: Authorize Access</h2>
    //     <p>You'll be redirected to Strava's authorization page. Click "Authorize" to grant Roast My Strava access to your activity data.</p>
    //     <h2>Step 3: Get Roasted!</h2>
    //     <p>Once connected, you'll be taken to your dashboard. Click the "Roast Me" button to receive your personalized, AI-generated roast based on your Strava activities.</p>
    //     <p>Remember, it's all in good fun! Enjoy your roast and share it with your friends for a good laugh.</p>
    //   `,
    // },
    "strava-announces-athlete-intelligence": {
      title:
        "Strava Announces Athlete Intelligence: A New Era for Personalized Performance Insights",
      intro:
        "Strava introduces Athlete Intelligence, a groundbreaking AI-powered feature that revolutionizes how athletes interact with their fitness data, providing personalized performance insights.",
      content: `
        <p>Strava, the popular social platform for runners and cyclists, has recently <a href="https://press.strava.com/articles/stravas-athlete-intelligence-translates-workout-data-into-simple-and" target="_blank" rel="noopener noreferrer">introduced a groundbreaking feature called Athlete Intelligence</a>, aiming to revolutionize the way athletes interact with their fitness data. Strava's move to incorporate advanced AI-powered insights underscores its ongoing commitment to delivering cutting-edge tools for its global community of over 100 million users. Let's dive into what Athlete Intelligence is, how it came about, and what this means for athletes around the world.</p>

        <h2>What is Strava Athlete Intelligence?</h2>
        <p>Athlete Intelligence is Strava's latest initiative to enhance how users interpret and act on their activity data. Leveraging artificial intelligence, this feature provides deeper insights into performance, patterns, and personalized suggestions for improvement. According to Strava, <a href="https://stories.strava.com/articles/meet-athlete-intelligence-personalized-ai-insights-that-help-you-reach-your" target="_blank" rel="noopener noreferrer">Athlete Intelligence combines advanced data analytics with contextual information from users' activities</a>, offering tailored feedback such as performance highlights, suggested recovery times, and strategic tips to help athletes improve their training.</p>
        <p>Athlete Intelligence integrates with existing Strava features like Segments, Fitness & Freshness, and Heatmaps, enhancing them with AI-driven insights. For instance, it might provide runners with tailored pacing strategies based on their historical performance on specific segments or suggest optimal training days given their recent activity trends and weather conditions. The goal is to help athletes make better-informed decisions without the need for external coaching or deep manual analysis.</p>

        <h2>How Did Athlete Intelligence Come About?</h2>
        <p>The concept of Athlete Intelligence reflects trends in both technology and user demand. Strava has increasingly leaned into personalization and smart data, and Athlete Intelligence is a natural step forward in that trajectory. The introduction of AI follows Strava's <a href="https://bikebiz.com/strava-acquires-injury-prevention-app-recover-athletics/" target="_blank" rel="noopener noreferrer">acquisition of Recover Athletics</a>, an app focused on injury prevention through data-driven recommendations. This move aligns with a broader trend of leveraging machine learning and artificial intelligence to make sense of the vast amount of fitness data generated by users.</p>
        <p>Strava's CEO, Michael Horvath, has previously mentioned that <a href="https://insider.fitt.co/137-michael-horvath-ceo-co-founder-of-strava/" target="_blank" rel="noopener noreferrer">user feedback highlighted a desire for more actionable insights from their data</a>. The Strava community consists of athletes who value competition, personal improvement, and social connection. Athlete Intelligence was born from this desire to provide users with proactive, meaningful insights to help guide training, avoid injury, and sustain motivation.</p>

        <h2>Where Will Strava Take Athlete Intelligence?</h2>
        <p>Strava has hinted that Athlete Intelligence is just the beginning of a broader AI integration strategy. Future updates could include predictive modeling to help athletes avoid overtraining or even AI-powered coaching plans based on personal activity data. Strava also plans to integrate Athlete Intelligence more deeply with community features, potentially offering group-based insights for club challenges or providing detailed comparisons between training partners.</p>
        <p>According to Strava, the company aims to be more than just a data aggregator—they want to become an indispensable training partner for their users. Athlete Intelligence has the potential to transform Strava into a platform that not only records activities but also actively guides athletes to become better versions of themselves.</p>

        <h2>Related Efforts and AI Applications in the Fitness Tech Space</h2>
        <p>Strava isn't alone in the quest to make training smarter through AI. Other platforms, such as Garmin and Nike Run Club, have also integrated AI to provide personalized coaching. Garmin's Coach feature uses machine learning to adapt training plans in real-time based on a user's performance and feedback, while Nike Run Club provides adaptive guidance that matches the user's progress and preferences.</p>
        <p>Additionally, WHOOP, the popular wearable known for its in-depth recovery metrics, has made significant strides in using data analytics to provide recommendations for sleep and strain management. The convergence of these efforts indicates that the running and cycling space is moving towards an era where AI and data analysis are foundational to athlete development. Personalized training plans, injury prevention, and optimal performance strategies are becoming less exclusive and more accessible to the everyday athlete.</p>
        <p>One of the most interesting adjacent developments to Strava's AI push is the rise of third-party AI tools like <a href="https://roastmystrava.com/" target="_blank" rel="noopener noreferrer">Roast My Strava</a>. Roast My Strava, a popular AI-powered app, offers a humorous and insightful way for athletes to receive feedback on their activities. Unlike Athlete Intelligence, Roast My Strava takes a more lighthearted approach, providing roasts and funny critiques of users' performances while also offering nuggets of actionable advice. This playful twist on data analysis has captured the imagination of users who are looking for both entertainment and genuine insights—showing just how diverse the applications of AI can be in the fitness space.</p>
        <p>The success of tools like Roast My Strava shows that athletes want more than just raw data—they crave context, personalization, and, in some cases, a good laugh as they build community and push toward their athletic goals. The increasing variety of AI applications across different platforms reflects the growing demand for a more engaging, individualized experience.</p>

        <h2>The Potential for AI in Running, Cycling, and Fitness</h2>
        <p>The potential for AI in the running and cycling world is massive. By combining vast amounts of individual user data with powerful analytics, athletes of all levels can benefit from insights that were once only available to elite professionals with personal coaches. Strava's Athlete Intelligence, coupled with initiatives from companies like Garmin and WHOOP, paints a picture of a future where every athlete can have a personal training assistant that not only tracks their progress but also provides personalized coaching to optimize performance.</p>
        <p>As the fitness tech landscape evolves, it's clear that AI will play an increasingly crucial role in helping athletes achieve their goals. Whether you're a casual runner who wants to stay healthy or an aspiring competitor training for your next race, AI-driven tools like Athlete Intelligence, Roast My Strava, and others are here to ensure you're equipped with the insights needed to get the most out of every mile.</p>
        <p>Strava's Athlete Intelligence marks an exciting step forward in the world of connected fitness. Are you ready to take your training to the next level with AI-driven insights? Whether you're diving into Athlete Intelligence or exploring the lighthearted side with Roast My Strava, there's never been a better time to leverage technology to help you achieve your fitness goals.</p>
    `,
    },
    // ... other blog posts ...
  };

  const post = blogPosts[blogName];

  if (!post) {
    return <BlogPostContainer>Blog post not found</BlogPostContainer>;
  }

  return (
    <BlogPostContainer>
      <BackLink to="/">← Roast My Strava</BackLink>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.intro} />
      </Helmet>
      <BlogTitle>{post.title}</BlogTitle>
      <BlogContent
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}
      />
      <BackLink to="/">← Roast My Strava</BackLink>
    </BlogPostContainer>
  );
};

export default BlogPost;
